<template>
  <div class="brickFactoryVideo">
    <div class="brickFactoryVideo-content">
      <div class="brickFactoryVideo-introduce clearfix">
        <div class="bv-box" v-for="(item, index) in videoDetailList" :key="index">
          <!-- 视频 -->
          <video
            class="edu-viedo"
            width="600px"
            height="300px"
            controls="controls"
            style="object-fit: cover"
            :src="item.img"
          ></video>
          <div class="bv-mainbody">
            <!-- 标题 -->
            <div class="bv-text">{{ item.title }}</div>
            <!-- 发布时间 -->
            <div class="bv-time">发布时间: {{ item.createTime }}</div>
            <!-- 详情 -->
            <div class="bv-content">{{ item.jsonText }}</div>
            <!-- more -->
            <!-- <div class="more">more<i class="el-icon-arrow-right"></i></div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="bv-pagination">
      <el-pagination
        background
        layout=" prev, pager, next"
        :total="pageCount"
        :page-size="6"
        @current-change="changePage"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios"; //引入axios
export default {
  name: "",
  data() {
    return {
      page: 1,
      pageDataList: [], //内容列表(分页前)
    };
  },
  watch: {},
  created() {},
  mounted() {
    this.queryTitleId();
  },
  methods: {
    queryTitleId() {
      let lang = localStorage.getItem("lang") || "CN";
      axios
        .get("https://www.claybbt.com/queryTitleId", {
          params: {
            language: lang,
            titleId: this.$route.query.nodeId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.pageDataList = res.data.data.pageDatas;
          } else {
            this.$message.error(data.msg);
          }
        });
    },
    //点击分页
    changePage(page) {
      this.page = page;
    },
  },
  computed: {
    // 初始化分页
    pageCount() {
      return this.pageDataList.length;
    },
    //默认显示第一个三级标题的内容
    videoDetailList() {
      // 分页
      //开始下标： (当前页数-1)*每页条数
      //结束下标： (开始下标+每页条数)-1
      let limit = 6;
      let startIndex = (this.page - 1) * limit;
      let endIndex = startIndex + limit - 1;
      return this.pageDataList.slice(startIndex, endIndex + 1);
    },
  },
  filters: {},
};
</script>

<style lang="less" scoped>
.brickFactoryVideo {
  width: 100%;
  background: #fafafa;
  padding: 50px 50px 10px;
  box-sizing: border-box;
  .brickFactoryVideo-content {
    display: flex;
    .brickFactoryVideo-introduce {
      min-height: 300px;
      width: 100%;
      .bv-box {
        display: flex;
        justify-content: space-between;
        background-color: #f3f3f4;
        width: 100%;
        margin-bottom: 35px;
        .bv-mainbody {
          width: 430px;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-between;
          padding: 50px 0;
          box-sizing: border-box;
          .bv-text {
            width: 375px;
            color: #393939;
            display: block;
            font-size: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .bv-time {
            font-size: 12px;
            color: #b6b6b9;
            display: block;
            margin: 30px 0;
          }
          .bv-content {
            width: 375px;
            font-size: 14px;
            color: #797979;
            line-height: 24px;
            height: 70px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
          }
          .more {
            width: 90px;
            height: 30px;
            border: 1px solid rgb(231, 231, 231);
            color: rgb(155, 155, 155);
            line-height: 30px;
            text-align: center;
            background-color: rgb(255, 255, 255);
          }
        }
      }
    }
  }

  .bv-pagination {
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      color: rgb(255, 255, 255);
      background: #d80930;
    }
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: rgb(255, 255, 255);
      background: #d80930;
    }
  }
}
</style>
